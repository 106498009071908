import React from 'react';
import './Navbar.css';

const Navbar = () => {
    return (
        <nav className="navbar">
            <a href="/" className="logo">
                <img src={`${process.env.PUBLIC_URL}/logo-new.png`} alt="Gravity Logo" />
            </a>
            <ul className="nav-links">
                <li><a href="/">Home</a></li>
                <li><a href="/privacy">Privacy Policy</a></li>
                <li><a href="/team">Meet The Team</a></li>
                <li><a href="/support">Support</a></li>
            </ul>
        </nav>
    );
};

export default Navbar;
