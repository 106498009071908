// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Support from './components/Support';
import '../src/components/Styles.css';
import Team from './components/Team';
import Portfolio from './components/Portfolio';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route exact path="/team" element={<Team />} />
                <Route path="/team/cole-miller" element={<Portfolio />} />
                <Route path="/support" element={<Support />} />
            </Routes>
        </Router>
    );
};

export default App;
