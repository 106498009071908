// src/components/Portfolio.js
import React from 'react';
import Navbar from './Navbar'; // Assuming you have a Navbar component
import './Portfolio.css';

const projects = [
    {
        title: 'Gravity - Social Navigation App',
        description: `
            Developed and launched Gravity, a social navigation app on the App Store, reaching over 170 users within the first two weeks of launch.
            Built using React Native and Expo, employing React Navigation and Context API for efficient state management and seamless user experience.
            Implemented real-time features with Firebase and Google Cloud for authentication, database management, and user presence updates.
        `,
        link: 'https://thegravity.app/',
    },
    {
        title: 'CNN-based Card Recognition System for Embedded Devices',
        description: `
            Designed and trained a Convolutional Neural Network (CNN) model for an automatic card counting system, aimed at deployment on an ESP32-S3 microcontroller.
            Created a custom dataset of playing card images in controlled environments to ensure high precision in recognition.
            Optimized the model for embedded deployment by applying quantization and converting it to TensorFlow Lite, achieving a model size suitable for resource-constrained devices without significant loss in accuracy.
            Demonstrated successful inference on the microcontroller, highlighting the feasibility of deploying machine learning models on embedded hardware.
        `,
        link: 'https://colab.research.google.com/drive/1U0kv4NKpfizHVUA02YtCSm8OlfNwyhR-?usp=sharing',
    },
    {
        title: 'Chess Move Predictor',
        description: `
            Collaborated on a machine learning project to predict the next move in a chess game based on the current board state.
            Developed the Convolutional Neural Network (CNN) models responsible for predicting piece movements.
            Implemented board and move encoding functions to convert FEN (Forsyth-Edwards Notation) and UCI (Universal Chess Interface) formats into neural network-compatible inputs and outputs.
            Created a set of CNNs, including a primary model for piece selection and piece-specific models for move prediction.
            Integrated the CNN models with a React frontend and Flask API, providing a user-friendly interface for real-time chess move predictions.
        `,
        link: 'https://github.com/ChristianLindler/chessengine',
    },
    {
        title: 'Monte Carlo Options Pricer',
        description: `
            Developed a Monte Carlo options pricer using the Heston model for path generation and Longstaff-Schwartz algorithm for American options pricing.
            Implemented an optimization module for calibrating model parameters, minimizing the error between market and model prices through objective function design and parameter tuning.
            Engineered data preprocessing and visualization functions for efficient simulation and analysis of option price paths.
        `,
        link: 'https://www.optionspricerapp.com/',
    },
    {
        title: 'Portfolio Optimization with Reinforcement Learning (In Progress)',
        description: `
            Developed a reinforcement learning agent to optimize a financial portfolio consisting of multiple stocks.
            Utilized Gymnasium for environment creation and Stable Baselines3's Proximal Policy Optimization (PPO) algorithm for training.
            Prepared and processed historical stock data using yfinance, integrating technical indicators like Moving Averages and RSI to enrich the observation space.
            Designed a custom reward function to maximize portfolio returns while managing risks, accounting for transaction costs and portfolio rebalancing.
            Trained and evaluated the agent, demonstrating improved portfolio performance in simulated trading environments.
            Explored hyperparameter tuning, advanced risk management strategies, and implemented action space constraints to enhance the agent's effectiveness.
        `,
        link: 'https://colab.research.google.com/drive/171R2W9KQ_LvZ-XGOucCzjza-q-Tx5qgt?usp=sharing', 
    },
];

const Portfolio = () => {
    return (
        <div className="portfolio-page">
            <Navbar />
            <div className="portfolio-header">
                <img
                    src={`${process.env.PUBLIC_URL}/cole-headshot.png`}
                    alt="Cole Miller"
                    className="portfolio-photo"
                />
                <h1>Cole Miller</h1>
            </div>
            <div className="portfolio-bio">
                <p>
                    I am a passionate software developer with a Bachelor's degree in Computer Science specializing in Devices and Intelligence from Georgia Institute of Technology, graduating in December 2024. Currently, I am pursuing a Master's degree in Computer Science with a specialization in Machine Learning, expected to graduate in December 2025.
                    <br /><br />
                    My experience includes a forthcoming role as a Machine Learning Intern at Genuine Parts Company, where I will engineer machine learning pipelines for product categorization. Previously, I worked as a Software Engineering Intern at Priority5, where I spearheaded the development of new features in TACCS™, a real-time geospatial enterprise software.
                    <br /><br />
                    My expertise lies in machine learning, software development, and building scalable applications. I'm proficient in Python, JavaScript, Java, C++, and have hands-on experience with technologies like Kubernetes, Docker, Firebase, and Google Cloud Services.
                </p>
            </div>
            <div className="portfolio-links">
                <a
                    href={`${process.env.PUBLIC_URL}/cmresume.pdf`}
                    download
                    className="resume-link"
                >
                    Download Resume
                </a>
                <a
                    href="https://www.linkedin.com/in/coleqmiller/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="linkedin-link"
                >
                    LinkedIn
                </a>
                <a
                    href="https://github.com/CMiller383"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="github-link"
                >
                    GitHub
                </a>
            </div>
            <div className="portfolio-projects">
                <h2>Projects</h2>
                {projects.map((project, index) => (
                    <div className="project" key={index}>
                        <h3>{project.title}</h3>
                        <p>{project.description.trim()}</p>
                        {project.link ? (
                            <a href={project.link} target="_blank" rel="noopener noreferrer">
                                View Project
                            </a>
                        ) : (
                            <p className="no-link">Project link available upon request</p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Portfolio;
