import React from 'react';
import Navbar from './Navbar';
import './Support.css';

const Support = () => {
    return (
        <div className="support">
            <Navbar />
            <div className="support-container">
                <h1>Gravity App Support</h1>

                <section className="overview-section">
                    <h2>Overview</h2>
                    <p>
                        Welcome to the support page for the Gravity app! Here you can find answers to common questions
                        and reach out for additional help. Gravity helps you connect with friends and your school community 
                        to find out who's going out and where they're going.
                    </p>
                </section>

                <section className="faq-section">
                    <h2>Frequently Asked Questions</h2>
                    <div className="faq">
                        <h3>How do I sign up or log in?</h3>
                        <p>
                            Log in with your phone number, and your account will be linked to your college or university. 
                            Sign up using your college email, which will be verified before you can access the school circle.
                        </p>
                    </div>

                    <div className="faq">
                        <h3>What should I do if I lost access to my phone number?</h3>
                        <p>
                            Contact us at our support email, let us know your old and new phone numbers, and provide account 
                            details to confirm your identity.
                        </p>
                    </div>

                    <div className="faq">
                        <h3>How do I join or create a circle?</h3>
                        <p>
                            Join a circle through an invite link or create a new one directly in the app. 
                            You are automatically added to your school circle after verification.
                        </p>
                    </div>
                </section>

                <section className="contact-section">
                    <h2>Contact Support</h2>
                    <p>If you need further help, feel free to reach out to us via the form below or email us at <a href="mailto:support@gravity-support.com">support@gravity-support.com</a>.</p>
                    
                    <form className="contact-form" action="mailto:support@gravity-support.com" method="post" enctype="text/plain">
                        <input type="text" name="name" placeholder="Your Name" required />
                        <input type="email" name="email" placeholder="Your Email" required />
                        <textarea name="message" rows="5" placeholder="Your Message" required></textarea>
                        <button type="submit">Send Message</button>
                    </form>
                </section>

                <section className="privacy-policy-section">
                    <h2>Privacy Policy</h2>
                    <p>
                        We take your privacy seriously. Please read our <a href="/privacy">Privacy Policy</a> to understand 
                        how we collect and use your data.
                    </p>
                </section>
            </div>
            <footer className="footer">
                <p>&copy; 2024 Gravity. All rights reserved. <a href="/terms">Terms of Service</a> | <a href="/support">Contact Support</a></p>
            </footer>
        </div>
    );
};

export default Support;
