import React from 'react';
import Navbar from './Navbar';
import './Privacy.css';

const TermsOfService = () => {
    return (
        <div className="privacy">
            <Navbar />
            <div className="privacy-container">
                <h1>Terms of Service</h1>
                <p><strong>Last Updated: 10/13/2024</strong></p>

                <p>Welcome to Gravity! By using our app, you agree to abide by these Terms of Service. Please read them carefully. If you do not agree with these terms, you must not use the app.</p>

                <h2>1. Acceptance of Terms</h2>
                <p>By accessing and using Gravity, you accept and agree to be bound by these Terms of Service and our Privacy Policy. We reserve the right to modify these terms at any time, and such modifications will be effective immediately upon posting.</p>

                <h2>2. User Conduct</h2>
                <p>You agree to use Gravity responsibly and agree not to:</p>
                <ul>
                    <li>Post, share, or transmit any content that is offensive, abusive, harassing, threatening, hateful, discriminatory, or otherwise objectionable.</li>
                    <li>Engage in behavior that could be considered bullying, harassment, or other inappropriate conduct.</li>
                    <li>Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity.</li>
                    <li>Collect or store personal data about other users without their express consent.</li>
                </ul>

                <h2>3. User-Generated Content</h2>
                <p>You are solely responsible for any content you submit, post, or display on Gravity, including but not limited to your full name, screen name, bio, and avatar. We reserve the right to remove or modify any content that violates these Terms of Service.</p>

                <h2>4. Reporting and Flagging</h2>
                <p>If you encounter content that violates these Terms of Service, you may report it to us using the app’s reporting feature. We will review all reports and take appropriate action, which may include removing content or suspending user accounts.</p>

                <h2>5. Termination of Use</h2>
                <p>We reserve the right to suspend or terminate your access to Gravity at our discretion, without notice, for conduct that we believe violates these Terms of Service or is harmful to other users, us, or third parties, or for any other reason.</p>

                <h2>6. Limitation of Liability</h2>
                <p>We are not liable for any damages arising from your use of Gravity or from any content posted by other users. Your use of the app is at your own risk.</p>

                <h2>7. Changes to These Terms</h2>
                <p>We may update these Terms of Service from time to time. If we make significant changes, we will notify you by posting the new terms on the app and updating the "Last Updated" date above. Continuing to use the app after changes are posted means you agree to be bound by the new terms.</p>

                <h2>8. Contact Us</h2>
                <p>If you have questions or concerns about these Terms of Service, please contact us at <a href="mailto:support@gravity-support.com">support@gravity-support.com</a>.</p>
            </div>
            <footer className="footer">
                <p>&copy; 2024 Gravity. All rights reserved. <a href="/terms">Terms of Service</a> | <a href="/support">Contact Support</a></p>
            </footer>
        </div>
    );
};

export default TermsOfService;
