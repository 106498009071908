// src/components/Team.js
import React from 'react';
import Navbar from './Navbar';
import './Team.css';

const Team = () => {
    return (
        <div className="team-page">
            <Navbar />
            <section className="team-section">
                <h2>Meet the Team</h2>
                <div className="team-members">
                    <div className="team-member">
                        <img src={`${process.env.PUBLIC_URL}/cole-headshot.png`} alt="Cole Miller" className="team-photo" />
                        <h3>Cole Miller</h3>
                        <p className="team-title">Lead Developer & Technical Lead</p>
                        <p className="team-bio">
                            Undergraduate degree in Computer Science working towards a Masters in Computer Science with a Specialization in Machine Learning.
                            <br /><br />
                            As the technical lead for Gravity, Cole manages everything from software design to implementation, ensuring the app runs smoothly and all features are integrated effectively. With a strong focus on technical decisions, Cole brings the project’s vision to life through his coding expertise.
                        </p>
                        <a href="/team/cole-miller" className="portfolio-link">View Portfolio</a>
                    </div>
                    <div className="team-member">
                        <img src={`${process.env.PUBLIC_URL}/logo-new.png`} alt="Tobe Chanow" className="team-photo" />
                        <h3>Tobe Chanow</h3>
                        <p className="team-title">Business Strategist & Operations Lead</p>
                        <p className="team-bio">
                        Finishing Undergraduate degree in Business Administration focusing on Information Technology Management
                        <br /><br />
                        As the business lead for Gravity, Tobias oversees strategic planning and day-to-day operations. Tobias drives the project forward through effective decision-making, team coordination, and a commitment to long-term brand development. He works to build a robust business model that aligns with the company’s vision.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Team;
