// src/components/Header.js
import React from 'react';
import './Header.css'; 

const Header = () => {
    return (
        <header className="hero">
            <nav className="navbar">
                <ul className="nav-links">
                    <li><a href="#features">Features</a></li>
                    <li><a href="/privacy">Privacy Policy</a></li>
                    <li><a href="/team">Meet The Team</a></li>  
                    <li><a href="/support">Support</a></li>
                </ul>
            </nav>
            <div className="hero-content">
                <div className="center-logo">
                    <img src={`${process.env.PUBLIC_URL}/text-logo-white2.png`} alt="Gravity Logo" />
                </div>
                <h1>Navigate College Nightlife with Gravity</h1>
                <p>Stay in the loop with your friends, see who's going out, and never miss a night of fun.</p>
            </div>
        </header>
    );
};

export default Header;
