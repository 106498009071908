import React from 'react';
import Navbar from './Navbar';
import './Privacy.css';

const Privacy = () => {
    return (
        <div className="privacy">
            <Navbar />
            <div className="privacy-container">
                <h1>Privacy Policy</h1>
                <p><strong>Last Updated: 10/16/2024</strong></p>

                <p>Welcome to Gravity (“we,” “our,” “us”). We are committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, and share your personal information when you use our app.</p>

                <h2>1. Information We Collect</h2>
                <p>We collect the following personal information from you when you use our app:</p>
                <ul>
                    <li><strong>Name:</strong> The name you provide during sign-up.</li>
                    <li><strong>Avatar:</strong> Your profile picture or avatar.</li>
                    <li><strong>College:</strong> The college or university you attend.</li>
                    <li><strong>College Email:</strong> Your official college or university email address.</li>
                    <li><strong>Phone Number:</strong> Your contact phone number.</li>
                    <li><strong>Bio:</strong> A short description or bio you choose to share.</li>
                    <li><strong>Current Bar Location:</strong> The bar or venue you are currently attending on a night out.</li>
                    <li><strong>Push Token:</strong> A unique identifier for sending you push notifications.</li>
                    <li><strong>Screen Name:</strong> The name displayed to others within the app.</li>
                    <li><strong>Circles and Group Activity:</strong> Information about the groups (“circles”) you create or join, and your location when going out, as customized in your settings.</li>
                </ul>

                <h2>2. How We Use Your Information</h2>
                <p>We use your personal information to:</p>
                <ul>
                    <li>Provide and improve our services, ensuring a seamless user experience.</li>
                    <li>Allow you to connect with other students from your college and within your circles.</li>
                    <li>Display the number of students from your college going out to bars and, within your circles, show specifically who is going where.</li>
                    <li>Enable you to customize your privacy settings regarding the visibility of your going-out location.</li>
                    <li>Send you push notifications about relevant activities or updates within the app.</li>
                </ul>

                <h2>3. How We Share Your Information</h2>
                <p>We do not share your personal information with third parties except in the following circumstances:</p>
                <ul>
                    <li><strong>With Your Consent:</strong> We may share your information with others if you have given us explicit consent to do so.</li>
                    <li><strong>For Legal Reasons:</strong> We may share your information if required by law or in response to a legal request.</li>
                    <li><strong>To Protect Rights and Safety:</strong> We may share information to protect the rights, safety, or property of our users, ourselves, or others.</li>
                </ul>

                <h2>4. Your Privacy Choices</h2>
                <p>You have control over the information you share and how it is displayed within the app:</p>
                <ul>
                    <li><strong>Account Deletion:</strong> You can delete your account at any time, which will remove all your personal information from our systems.</li>
                    <li><strong>Profile Information:</strong> You can update or remove your name, avatar, bio, and other profile information at any time.</li>
                </ul>

                <h2>5. Data Security</h2>
                <p>We take the security of your personal information seriously and implement industry-standard security measures to protect it from unauthorized access, alteration, or disclosure. However, please note that no method of transmission over the internet or method of electronic storage is 100% secure.</p>

                <p>All data you provide is stored and managed using third-party cloud services, specifically Firebase, a service provided by Google. Firebase implements robust security measures, including encryption and access controls, to safeguard your data. However, by using our app, you acknowledge and agree that your data will be processed by these third-party services, and that we are not responsible for any breaches or issues that arise from their systems.</p>

                <h2>6. Your Rights</h2>
                <p>You have the right to:</p>
                <ul>
                    <li>Access the personal information we hold about you.</li>
                    <li>Request the correction or deletion of your personal information.</li>
                    <li>Object to or restrict the processing of your personal information.</li>
                    <li>Withdraw your consent at any time, where we rely on your consent to process your data.</li>
                </ul>

                <h2>7. Changes to This Privacy Policy</h2>
                <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the new Privacy Policy on the app and updating the "Last Updated" date at the top.</p>

                <h2>8. Contact Us</h2>
                <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <a href="mailto:support@gravity-support.com">support@gravity-support.com</a>.</p>
            </div>
            <footer className="footer">
                <p>&copy; 2024 Gravity. All rights reserved. <a href="/terms">Terms of Service</a> | <a href="/support">Contact Support</a></p>
            </footer>
        </div>
    );
};

export default Privacy;
