// src/components/Home.js
import React from 'react';
import Header from './Header';
import './Home.css';

const Home = () => {
    return (
        <div className="home">
            <Header />
            <section id="features" className="features-section">
                <h2>Key Features</h2>
                <div className="features-container">
                    <div className="feature">
                        <h3>Automatic College Circles</h3>
                        <p>Join your college's circle by default and instantly connect with peers attending the same bars and events.</p>
                    </div>
                    <div className="feature">
                        <h3>Custom Circles for Groups</h3>
                        <p>Create your own circles for friend groups, organizations, and keep track of where everyone is going throughout the night.</p>
                    </div>
                    <div className="feature">
                        <h3>Explore Social Hotspots</h3>
                        <p>Check out the bars and events that are popular at your school. Suggest new spots if your favorites aren’t listed.</p>
                    </div>
                    <div className="feature">
                        <h3>Beta Testing in Atlanta, GA</h3>
                        <p>Currently available only in the Atlanta region. More cities coming soon!</p>
                    </div>
                </div>
            </section>
            <section className="download-section">
                <h2>Download Gravity Today</h2>
                <p>Experience the best way to navigate your night. Connect with friends, discover events, and stay in the loop with what's happening around you.</p>
                <a href="https://apps.apple.com/us/app/gravity-navigate-your-night/id6636511847" target="_blank" rel="noopener noreferrer">
                    <img src="app-store-badge.svg" alt="Download on the App Store" className="app-store-badge" />
                </a>
            </section>
            <section className="call-to-action">
                <h2>Stay in the Know</h2>
                <p>Join the Gravity community today and get early access to all the hottest social scenes on your campus.</p>
                <a href="https://www.instagram.com/thegravity.app" target="_blank" rel="noopener noreferrer">
                    <img src="Instagram_Glyph_Gradient.png" alt="Follow us on Instagram" className="social-icon" />
                </a>
            </section>
            <footer className="footer">
                <p>&copy; 2024 Gravity. All rights reserved. <a href="/terms">Terms of Service</a> | <a href="/support">Contact Support</a></p>
            </footer>
        </div>
    );
};

export default Home;
